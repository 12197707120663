import { createTheme } from '@mui/material/styles'

const theme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
    },
  },
  palette: {
    primary: {
      main: '#0178DD',
    },

    secondary: {
      main: '#F5F5F5',
      dark: '#1D2426',
    },
    warning: {
      main: '#FFC260',
    },
  },
})

export default theme
