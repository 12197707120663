export const BASE_URL =
  process.env.REACT_APP_BASE_URL || 'https://system.enhance.care'
export const STAGING_BASE_URL =
  process.env.REACT_APP_MAP_URL || 'https://system.enhance.care'
export const EMAIL_BASE_URL =
  process.env.REACT_APP_EMAIL_URL || 'https://email-staging.enhance.care'
export const SMS_URL =
  process.env.REACT_APP_SMS_URL || 'https://sms-staging.enhance.care'
// export const EMAIL_BASE_URL =
// process.env.REACT_APP_EMAIL_URL || 'http://64.226.72.36:4010'
// export const SMS_URL =
//   process.env.REACT_APP_SMS_URL || 'http://64.226.72.36:8011'
