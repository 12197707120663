import { QueryClient, QueryClientProvider } from 'react-query'
import { LocalizationProvider } from '@mui/x-date-pickers'
import {
  CircularProgress,
  CssBaseline,
  Slide,
  ThemeProvider,
} from '@mui/material'
import theme from './theme'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { SnackbarProvider } from 'notistack'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { lazy, Suspense, useEffect, useRef } from 'react'
import { CheckAuth } from './components/auth/CheckAuth'
import { useState } from 'react'
import { UserContext } from './context/UserContext'
import ReceiveCall from './pages/ReceiveCall'
import {
  CallsApiEvent,
  createInfobipRtc,
  InfobipRTCEvent,
  WebrtcCall,
} from 'infobip-rtc'
import { CallContext } from './context/callContext'
import axios from 'axios'
import './App.css'
import { registerLicense } from '@syncfusion/ej2-base';
import InCall from './pages/InCall'
import { SMS_URL } from './utils/config'
const Login = lazy(() => import('./pages/Login'))
const Layout = lazy(() => import('./components/Layout/Layout'))
const UserManagement = lazy(() => import('./pages/UserManagement'))
const Dashboard = lazy(() => import('./pages/Dashboard'))
const ResetPassword = lazy(() => import('./pages/ResetPassword'))
const VerifyOtp = lazy(() => import('./pages/VerifyOtp'))
const SetNewPassword = lazy(() => import('./pages/SetNewPassword'))
const Appointments = lazy(() => import('./pages/Appointments'))
const CompanyRegistration = lazy(() => import('./pages/CompanyRegistration'))
const Configurations = lazy(() => import('./pages/Configurations'))
const Alerts = lazy(() => import('./pages/Alerts'))
const Clients = lazy(() => import('./pages/Clients'))
const Goals = lazy(() => import('./pages/Goals'))
const Directory = lazy(() => import('./pages/Directory'))
const Messaging = lazy(() => import('./pages/Messaging'))
const ClientDetail = lazy(() => import('./pages/ClientDetail'))
const Reports = lazy(() => import('./pages/Reports'))
const Documents = lazy(() => import('./pages/Documnets'))
const ActivityLog = lazy(() => import('./pages/ActivityLog'))
const Settings = lazy(() => import('./pages/Settings'))
const Billing = lazy(() => import('./pages/Billing'))
const Support = lazy(() => import('./pages/Support'))
const InfoBipCall = lazy(() => import('./pages/InfobipCall'))
const WebCall = lazy(() => import('./pages/WebCall'))
const OnCall = lazy(() => import('./pages/OnCall'))

const queryClient = new QueryClient()
registerLicense('ORg4AjUWIQA/Gnt2UlhhQlVMfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hTX9SdkJjW3xddXNVQ2Nf;MzU2NjE4NkAzMjM3MmUzMDJlMzBhTkd4RlN3RHByUFBIUEhlZ2UwWW5pQ1EvelE0dWZvRFhWaTFGdldSU0JzPQ==');
function App() {
  const [user, setUser] = useState() as any
  const [callState, setCallState] = useState<{
    infobipRTC: ReturnType<typeof createInfobipRtc> | null
    activeCall: any
    identity: string
    status: string
    participants: any[]
    selectedAudioQualityMode: string
    audioInputDevices: any
    callerId: string
    isCallEstablished?: boolean
    isIncomingCall: boolean
  }>({
    infobipRTC: null,
    activeCall: null,
    identity: '',
    status: '',
    participants: [],
    selectedAudioQualityMode: 'Auto',
    audioInputDevices: null,
    callerId: '',
    isCallEstablished: false,
    isIncomingCall: false,
  })
  // console.log({ callState })

  const audioRef = useRef(null)
  const videoRef = useRef(null) as any

  console.log(4455566, videoRef?.current?.srcObject)

  const connectInfobipRTC = async () => {
    axios
      .post(`${SMS_URL}/webrtc/session`, {
        caller_identity: user?.id,
      })
      .then((response) => {
        const token = response?.data?.[0]?.data?.token
        const infobipRTC = createInfobipRtc(token, { debug: true })

        setCallState((state) => ({
          ...state,
          infobipRTC: infobipRTC,
        }))
        infobipRTC.on(InfobipRTCEvent.CONNECTED, (event) => {
          setCallState((state) => ({
            ...state,
            identity: event.identity,
          }))
          console.log('Connected to Infobip RTC Cloud with: %s', event.identity)
        })
        infobipRTC.on(InfobipRTCEvent.DISCONNECTED, (event) => {
          console.warn('Disconnected from Infobip RTC Cloud.')
        })
        infobipRTC.connect()
        loadAudioDevices()
      })
      .catch((err) => {
        console.error(err)
      })
  }

  const loadAudioDevices = () => {
    callState.infobipRTC?.getAudioInputDevices().then((inputDevices) =>
      setCallState((state) => ({
        ...state,
        audioInputDevices: inputDevices,
      }))
    )
  }

  useEffect(() => {
    if (user) {
      connectInfobipRTC()
    }
  }, [user])

  const listenForApplicationCallEvents = (call: WebrtcCall) => {
    console.log(3333333333)
    call.on(CallsApiEvent.RINGING, () => {
      console.log(444444444)
      setCallState((state) => ({
        ...state,
        status: 'Ringing...',
      }))
      console.log('Call is ringing...')
    })
    call.on(CallsApiEvent.ESTABLISHED, (event) => {
      console.log(55555555555)

      setCallState((state) => ({
        ...state,
        status: 'Established...',
      }))
      setMediaStream(audioRef.current, event.stream)
      setCallState((state) => ({
        ...state,
        isCallEstablished: true,
      }))
    })
    call.on(CallsApiEvent.HANGUP, (event) => {
      setCallState((state) => ({
        ...state,
        status: 'Call finished, errorCode: ' + event.errorCode.name,
      }))
      console.log('Call finished, errorCode: ' + event.errorCode.name)
      if (event?.errorCode?.name !== 'ANSWERED_ELSEWHERE') {
        setValuesAfterCall()
      }
    })
    call.on(CallsApiEvent.ERROR, (event) => {
      console.log(
        'Oops, something went very wrong! Message: ' + JSON.stringify(event)
      )
    })

    call.on(
      CallsApiEvent.PARTICIPANT_CAMERA_VIDEO_ADDED as any,
      (event: any) => {
        console.log('CAMERA ENABLED')
      }
    )

    call.on(
      CallsApiEvent.PARTICIPANT_CAMERA_VIDEO_REMOVED as any,
      (event: any) => {
        console.log('CAMERA DISABLED')
      }
    )
  }

  const setValuesAfterCall = () => {
    setCallState((state) => ({
      ...state,
      activeCall: null,
      status: '',
      selectedAudioQualityMode: 'Auto',
    }))
  }

  const setApplicationCallEventHandlers = (call: WebrtcCall) => {
    console.log(3333333333)
    call.on(CallsApiEvent.RINGING, () => {
      console.log(444444444444)
      setCallState((state) => ({
        ...state,
        status: 'Ringing...',
      }))
    })
    call.on(CallsApiEvent.ESTABLISHED, (event) => {
      console.log(55555555555)

      setCallState((state) => ({
        ...state,
        status: 'Established...',
      }))
      console.log('Call is established...')

      setMediaStream(audioRef.current, event.stream)
      setMediaStream(videoRef.current, event.stream)

      setCallState((state) => ({
        ...state,
        isCallEstablished: true,
      }))
    })
    call.on(CallsApiEvent.HANGUP, (event) => {
      setCallState((state) => ({
        ...state,
        status: 'Call finished, errorCode: ' + event.errorCode.name,
      }))

      console.log('Call finished, errorCode: ' + event.errorCode.name)
      if (event?.errorCode?.name !== 'ANSWERED_ELSEWHERE') {
        setValuesAfterCall()
      }
    })
    call.on(CallsApiEvent.ERROR, (event) => {
      console.log(
        'Oops, something went very wrong! Message: ' + JSON.stringify(event)
      )
    })
    call.on(
      CallsApiEvent.PARTICIPANT_CAMERA_VIDEO_ADDED as any,
      (event: any) => {
        console.log(888888888888)
      }
    )

    call.on(
      CallsApiEvent.PARTICIPANT_CAMERA_VIDEO_REMOVED as any,
      (event: any) => {
        console.log('CAMERA DISABLED')
      }
    )
  }

  useEffect(() => {
    console.log(11111111111111)
    if (callState.activeCall) {
      console.log(222222222222222)
      listenForApplicationCallEvents(callState.activeCall as WebrtcCall)
    }
  }, [callState])

  useEffect(() => {
    if (callState.infobipRTC) {
      callState.infobipRTC?.on(
        InfobipRTCEvent.INCOMING_WEBRTC_CALL,
        (incomingApplicationCallEvent: any) => {
          // const incomingCall = incomingApplicationCallEvent.incomingCall
          // console.log('Received incoming call from: ' + incomingCall.from())
          const call = incomingApplicationCallEvent.incomingCall
          console.log('Received incoming call from: ', call.source().identifier)

          setCallState((state) => ({
            ...state,
            activeCall: call,
            isIncomingCall: true,
            status: 'Incoming call from: ' + call.source().identifier,
            callerId: call.source().identifier,
          }))

          setApplicationCallEventHandlers(call)
        }
      )
    }
  }, [callState])

  const setMediaStream = (element: any, stream: any) => {
    console.log({ element, stream })
    element.srcObject = stream
  }

  return (
    <QueryClientProvider client={queryClient}>
      <SnackbarProvider
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        TransitionComponent={Slide}
      >
        <UserContext.Provider value={{ user, setUser }}>
          <CallContext.Provider value={{ callState, setCallState }}>
            <ThemeProvider theme={theme}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <CssBaseline />
                <Router>
                  <Routes>
                    <Route
                      path='/login'
                      element={
                        <Suspense fallback={<CircularProgress />}>
                          <Login />
                        </Suspense>
                      }
                    />
                    <Route
                      path='/resetpassword'
                      element={
                        <Suspense fallback={<CircularProgress />}>
                          <ResetPassword />
                        </Suspense>
                      }
                    />
                    <Route
                      path='/verifyotp'
                      element={
                        <Suspense fallback={<CircularProgress />}>
                          <VerifyOtp />
                        </Suspense>
                      }
                    />
                    <Route
                      path='/setnewpassword'
                      element={
                        <Suspense fallback={<CircularProgress />}>
                          <SetNewPassword />
                        </Suspense>
                      }
                    />
                    <Route
                      path='/companyregister'
                      element={
                        <Suspense fallback={<CircularProgress />}>
                          <CompanyRegistration />
                        </Suspense>
                      }
                    />
                    <Route
                      path='/'
                      element={
                        <Suspense fallback={<CircularProgress />}>
                          <CheckAuth>
                            <Layout />
                          </CheckAuth>
                        </Suspense>
                      }
                    >
                      <Route
                        path='/'
                        element={
                          <Suspense fallback={<CircularProgress />}>
                            <Dashboard />
                          </Suspense>
                        }
                      />
                      <Route
                        path='/usermanagement'
                        element={
                          <Suspense fallback={<CircularProgress />}>
                            <UserManagement />
                          </Suspense>
                        }
                      />
                      <Route
                        path='/appointments'
                        element={
                          <Suspense fallback={<CircularProgress />}>
                            <Appointments />
                          </Suspense>
                        }
                      />
                      <Route
                        path='/alerts'
                        element={
                          <Suspense fallback={<CircularProgress />}>
                            <Alerts />
                          </Suspense>
                        }
                      />
                      <Route
                        path='/configurations'
                        element={
                          <Suspense fallback={<CircularProgress />}>
                            <Configurations />
                          </Suspense>
                        }
                      />
                      <Route
                        path='/clients'
                        element={
                          <Suspense fallback={<CircularProgress />}>
                            <Clients />
                          </Suspense>
                        }
                      />
                      <Route
                        path='/goals'
                        element={
                          <Suspense fallback={<CircularProgress />}>
                            <Goals />
                          </Suspense>
                        }
                      />
                      <Route
                        path='/directory'
                        element={
                          <Suspense fallback={<CircularProgress />}>
                            <Directory />
                          </Suspense>
                        }
                      />
                      <Route
                        path='/clients/:id'
                        element={
                          <Suspense fallback={<CircularProgress />}>
                            <ClientDetail />
                          </Suspense>
                        }
                      />
                      <Route
                        path='/messaging/'
                        element={
                          <Suspense fallback={<CircularProgress />}>
                            <Messaging />
                          </Suspense>
                        }
                      >
                        <Route
                          path='call'
                          element={
                            <Suspense fallback={<CircularProgress />}>
                              <WebCall />
                            </Suspense>
                          }
                        />
                      </Route>

                      <Route
                        path='reports'
                        element={
                          <Suspense fallback={<CircularProgress />}>
                            <Reports />
                          </Suspense>
                        }
                      />
                      <Route
                        path='documents'
                        element={
                          <Suspense fallback={<CircularProgress />}>
                            <Documents />
                          </Suspense>
                        }
                      />
                      <Route
                        path='/activitylog'
                        element={
                          <Suspense fallback={<CircularProgress />}>
                            <ActivityLog />
                          </Suspense>
                        }
                      />
                      <Route
                        path='/settings'
                        element={
                          <Suspense fallback={<CircularProgress />}>
                            <Settings />
                          </Suspense>
                        }
                      />
                      <Route
                        path='/billing'
                        element={
                          <Suspense fallback={<CircularProgress />}>
                            <Billing />
                          </Suspense>
                        }
                      />
                      <Route
                        path='/support'
                        element={
                          <Suspense fallback={<CircularProgress />}>
                            <Support />
                          </Suspense>
                        }
                      />
                      <Route
                        path='/infobipcall'
                        element={
                          <Suspense fallback={<CircularProgress />}>
                            <InfoBipCall />
                          </Suspense>
                        }
                      />
                      <Route
                        path='/receivecall'
                        element={
                          <Suspense fallback={<CircularProgress />}>
                            <ReceiveCall />
                          </Suspense>
                        }
                      />
                      <Route
                        path='/messaging/call'
                        element={
                          <Suspense fallback={<CircularProgress />}>
                            <WebCall />
                          </Suspense>
                        }
                      />
                      <Route
                        path='/messaging/oncall'
                        element={
                          <Suspense fallback={<CircularProgress />}>
                            <OnCall />
                          </Suspense>
                        }
                      />
                      {/* <Route
                        path='incall'
                        element={
                          <Suspense fallback={<CircularProgress />}>
                            <InCall />
                          </Suspense>
                        }
                      /> */}
                    </Route>
                  </Routes>
                </Router>
              </LocalizationProvider>
              {callState?.activeCall &&
                callState?.status?.includes('Incoming call from:') && (
                  <ReceiveCall />
                )}
              {callState?.activeCall && callState?.isCallEstablished && (
                <InCall />
              )}
              <audio ref={audioRef} autoPlay />
              {/* <video ref={videoRef} autoPlay /> */}
            </ThemeProvider>
          </CallContext.Provider>
        </UserContext.Provider>
      </SnackbarProvider>
    </QueryClientProvider>
  )
}

export default App
