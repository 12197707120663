// import { createInfobipRtc } from 'infobip-rtc'
// import { createContext } from 'react'

// export const CallContext = createContext<{
//   infobipRTC: ReturnType<typeof createInfobipRtc> | null
//   activeCall: any
//   identity: string
//   status: string
//   participants: any[]
//   selectedAudioQualityMode: string
//   audioInputDevices: any
// }>({
//   infobipRTC: null,
//   activeCall: null,
//   identity: '',
//   status: '',
//   participants: [],
//   selectedAudioQualityMode: 'Auto',
//   audioInputDevices: null,
// })

import { createInfobipRtc } from 'infobip-rtc'
import { createContext } from 'react'

export const CallContext = createContext<{
  callState: {
    infobipRTC: ReturnType<typeof createInfobipRtc> | null

    activeCall: any

    identity: string

    status: string

    participants: any[]

    selectedAudioQualityMode: string

    audioInputDevices: any
    callerId: string
    isCallEstablished?: boolean
    isIncomingCall: boolean
  }

  setCallState: React.Dispatch<
    React.SetStateAction<{
      infobipRTC: ReturnType<typeof createInfobipRtc> | null

      activeCall: any

      identity: string

      status: string

      participants: any[]

      selectedAudioQualityMode: string

      audioInputDevices: any
      callerId: string
      isCallEstablished?: boolean
      isIncomingCall: boolean
    }>
  >
}>({
  callState: {
    infobipRTC: null,

    activeCall: null,

    identity: '',

    status: '',

    participants: [],

    selectedAudioQualityMode: 'Auto',

    audioInputDevices: null,
    callerId: '',
    isCallEstablished: false,
    isIncomingCall: false,
  },

  setCallState: () => {},
})
