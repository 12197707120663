import {
  Avatar,
  Box,
  IconButton,
  Modal,
  Slide,
  Typography,
} from '@mui/material'
import { useContext, useEffect, useRef } from 'react'
import { CallContext } from '../context/callContext'
import { useFetch } from '../hooks/useFetch'
import {
  CallEndOutlined,
  Mic,
  MoreHoriz,
  PersonAddAlt,
  VideocamOutlined,
} from '@mui/icons-material'
import { CallsApiEvent, WebrtcCall } from 'infobip-rtc'

export default function InCall() {
  const { callState, setCallState } = useContext(CallContext)
  const { data } = useFetch(
    'get one user',
    `/public/user/users/${callState?.callerId}`
  )

  const videoRef = useRef(null)

  const toggleCameraVideo = () => {
    callState.activeCall
      .cameraVideo(!callState.activeCall.hasCameraVideo())
      .catch((error: any) =>
        console.log('Error toggling camera video {}', error)
      )
    // callState.activeCall.cameraVideo(true)
  }

  const listenForApplicationCallEvents = (call: WebrtcCall) => {
    console.log(3333333333)
    call.on(CallsApiEvent.RINGING, () => {
      console.log(444444444)
      setCallState((state) => ({
        ...state,
        status: 'Ringing...',
      }))
      console.log('Call is ringing...')
    })
    call.on(CallsApiEvent.ESTABLISHED, (event) => {
      console.log(55555555555)

      setCallState((state) => ({
        ...state,
        status: 'Established...',
      }))
      setMediaStream(videoRef.current, event.stream)
      setCallState((state) => ({
        ...state,
        isCallEstablished: true,
      }))
    })
    call.on(CallsApiEvent.HANGUP, (event) => {
      setCallState((state) => ({
        ...state,
        status: 'Call finished, errorCode: ' + event.errorCode.name,
      }))
      console.log('Call finished, errorCode: ' + event.errorCode.name)
      if (event?.errorCode?.name !== 'ANSWERED_ELSEWHERE') {
        // setValuesAfterCall()
      }
    })
    call.on(CallsApiEvent.ERROR, (event) => {
      console.log(
        'Oops, something went very wrong! Message: ' + JSON.stringify(event)
      )
    })

    call.on(CallsApiEvent.REMOTE_CAMERA_VIDEO_ADDED as any, (event: any) => {
      console.log('CAMERA ENABLED')
    })

    call.on(
      CallsApiEvent.PARTICIPANT_CAMERA_VIDEO_REMOVED as any,
      (event: any) => {
        console.log('CAMERA DISABLED')
      }
    )
  }

  const setMediaStream = (element: any, stream: any) => {
    console.log({ element, stream })
    element.srcObject = stream
  }

  useEffect(() => {
    console.log(777777777777777, callState)
    listenForApplicationCallEvents(callState?.activeCall)
  }, [])

  return (
    <Modal open={true} closeAfterTransition>
      <Slide in={true} direction='right'>
        <Box sx={{ position: 'relative', float: 'left' }}>
          <Box
            sx={{
              height: '100vh',
              bgcolor: '#29A9D9',
              width: '100vw',
              boxShadow: '4px 4px 10px 1px rgba(0,0,0,0.07)',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: 2,
                flexWrap: 'wrap',
                height: '70%',
              }}
            >
              <Box
                sx={{
                  backgroundColor: 'rgba(255, 255, 255, 0.5)',
                  width: 300,
                  height: 300,
                  borderRadius: 3,
                  boxShadow: '4px 4px 10px 1px rgba(0,0,0,0.07)',
                }}
              >
                <Box sx={{ pt: 3 }}>
                  <Typography
                    sx={{ textAlign: 'center', fontSize: 18, color: 'white' }}
                  >
                    {data?.user?.firstName + ' ' + data?.user?.lastName}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    //   border: '1px solid red',
                    height: '80%',
                  }}
                >
                  <Avatar
                    sx={{ width: 120, height: 120 }}
                    src={data?.user?.imageUrl}
                  />
                </Box>
                <video ref={videoRef} autoPlay />
              </Box>
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
                gap: 5,
                py: 2,
                height: '20%',
                px: 5,
              }}
            >
              <Box
                sx={{
                  backgroundColor: 'rgba(255, 255, 255, 0.4)',
                  width: 150,
                  height: 150,
                  borderRadius: 3,
                  boxShadow: '4px 4px 10px 1px rgba(0,0,0,0.07)',
                  border: '1px solid white',
                }}
              >
                <Box sx={{ pt: 1 }}>
                  <Typography
                    sx={{ textAlign: 'center', fontSize: 18, color: 'white' }}
                  >
                    You
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '75%',
                  }}
                >
                  <Avatar
                    sx={{ width: 70, height: 70 }}
                    src={data?.user?.imageUrl}
                  />
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: 5,
                p: 2,
                height: '10%',
              }}
            >
              <IconButton>
                <Mic sx={{ color: 'white', width: 40, height: 40 }} />
              </IconButton>
              <IconButton onClick={toggleCameraVideo}>
                <VideocamOutlined
                  sx={{ color: 'white', width: 40, height: 40 }}
                />
              </IconButton>
              <IconButton
                sx={{ bgcolor: '#C44141', '&:hover': { bgcolor: 'red' } }}
              >
                <CallEndOutlined
                  sx={{ color: 'white', width: 30, height: 30 }}
                />
              </IconButton>
              <IconButton>
                <PersonAddAlt sx={{ color: 'white', width: 40, height: 40 }} />
              </IconButton>
              <IconButton>
                <MoreHoriz sx={{ color: 'white', width: 40, height: 40 }} />
              </IconButton>
            </Box>
          </Box>
        </Box>
      </Slide>
    </Modal>
  )
}
