export const getUser = () => {
  const item = localStorage.getItem('user') as string
  // console.log({ item })
  return JSON.parse(item)
}

export const authHeader = () => {
  const user = getUser()
  if (user) {
    // console.log({ user })
    return { Authorization: 'Bearer ' + user.accessToken }
  } else {
    return {}
  }
}

export const logout = () => {
  localStorage.removeItem('user')
}
