// import { connect } from 'http2'
// import { useContext, useEffect, useRef, useState } from 'react'
// import { UserContext } from '../context/UserContext'
// import axios from 'axios'
// import { SMS_URL } from '../utils/config'
// import {
//   ApplicationCallOptions,
//   CallsApiEvent,
//   createInfobipRtc,
//   InfobipRTCEvent,
//   WebrtcCall,
// } from 'infobip-rtc'
// import { Box, Button } from '@mui/material'

import { Call, CallEndOutlined, CallOutlined } from '@mui/icons-material'
import { Avatar, Box, Divider, IconButton, Typography } from '@mui/material'
import { useContext } from 'react'
import { CallContext } from '../context/callContext'
import { useFetch } from '../hooks/useFetch'
import { ApplicationCallOptions } from 'infobip-rtc'

// export default function ReceiveCall() {
//   const [state, setState] = useState<{
//     infobipRTC: any | null
//     activeCall: any
//     identity: string
//     status: string
//     isIncomingCall: boolean
//     isCallEstablished: boolean
//     participants: any[]
//     selectedAudioQualityMode: string
//     audioInputDevices: any
//   }>({
//     infobipRTC: null,
//     activeCall: null,
//     identity: '',
//     status: '',
//     isIncomingCall: false,
//     isCallEstablished: false,
//     participants: [],
//     audioInputDevices: [],
//     selectedAudioQualityMode: 'Auto',
//   })
//   const audioRef = useRef(null)

//   console.log({ state, audioRef })

//   const { user } = useContext(UserContext) as any

//   const loadAudioDevices = () => {
//     state.infobipRTC?.getAudioInputDevices().then((inputDevices: any) =>
//       setState((state) => ({
//         ...state,
//         audioInputDevices: inputDevices,
//       }))
//     )
//   }

//   const connectInfobipRTC = async () => {
//     axios
//       .post(
//         `https://e9f7-2a03-b0c0-3-d0-00-13fb-1.ngrok-free.app/webrtc/session`,
//         {
//           caller_identity: user?.id,
//         }
//       )
//       .then((response) => {
//         const token = response?.data?.[0]?.data?.token
//         const infobipRTC = createInfobipRtc(token, { debug: true })

//         setState((state) => ({
//           ...state,
//           infobipRTC: infobipRTC,
//         }))
//         infobipRTC.on(InfobipRTCEvent.CONNECTED, (event) => {
//           setState((state) => ({
//             ...state,
//             identity: event.identity,
//           }))
//           console.log('Connected to Infobip RTC Cloud with: %s', event.identity)
//         })
//         infobipRTC.on(InfobipRTCEvent.DISCONNECTED, (event) => {
//           console.warn('Disconnected from Infobip RTC Cloud.')
//         })
//         infobipRTC.connect()
//         listenForIncomingApplicationCall()
//         loadAudioDevices()
//       })
//       .catch((err) => {
//         console.error(err)
//       })
//   }

//   const listenForIncomingApplicationCall = () => {
//     console.log('Listen')
//     console.log({ info124: state.infobipRTC })
//     // state.infobipRTC?.on(
//     //   'incoming-webrtc-call',
//     //   (incomingWebrtcCallEvent: any) => {
//     //     // const incomingCall = incomingWebrtcCallEvent.incomingCall
//     //     // console.log({ incomingCall })

//     //     // const incomingCall = incomingApplicationCallEvent.incomingCall
//     //     // console.log(
//     //     //   'Received incoming call from: ' + incomingCall.applicationCall?.caller
//     //     // )
//     //     console.log('Received incoming call from: ')
//     //     console.log(111111, {
//     //       incomingWebrtcCallEvent:
//     //         incomingWebrtcCallEvent._incomingCall?.applicationCall?.caller,
//     //     })

//     //     setState((state) => ({
//     //       ...state,
//     //       activeCall: incomingWebrtcCallEvent?.incomingCall,
//     //       isIncomingCall: true,
//     //       status:
//     //         'Incoming ' +
//     //         (incomingWebrtcCallEvent?.incomingCall.options.video
//     //           ? 'video'
//     //           : 'audio') +
//     //         ' call from: ' +
//     //         incomingWebrtcCallEvent?.incomingCall.from(),
//     //     }))

//     //     setApplicationCallEventHandlers(incomingWebrtcCallEvent._incomingCall)
//     //   }
//     // )
//   }

//   useEffect(() => {
//     state.infobipRTC?.on(
//       'incoming-webrtc-call',
//       (incomingApplicationCallEvent: any) => {
//         // const incomingCall = incomingApplicationCallEvent.incomingCall
//         // console.log('Received incoming call from: ' + incomingCall.from())
//         const call = incomingApplicationCallEvent.incomingCall
//         console.log('Received incoming call from: ', call.source().identifier)

//         setState((state) => ({
//           ...state,
//           activeCall: call,
//           isIncomingCall: true,
//           status: 'Incoming call from: ' + call.source().identifier,
//         }))

//         setApplicationCallEventHandlers(call)
//       }
//     )
//   }, [state])

//   const setApplicationCallEventHandlers = (call: WebrtcCall) => {
//     call.on(CallsApiEvent.RINGING, () => {
//       setState((state) => ({
//         ...state,
//         status: 'Ringing...',
//       }))
//     })
//     call.on(CallsApiEvent.ESTABLISHED, (event) => {
//       setState((state) => ({
//         ...state,
//         status: 'Established...',
//       }))
//       console.log('Call is established...')

//       setMediaStream(audioRef.current, event.stream)

//       setState((state) => ({
//         ...state,
//         isCallEstablished: true,
//       }))
//     })
//     call.on(CallsApiEvent.HANGUP, (event) => {
//       setState((state) => ({
//         ...state,
//         status: 'Call finished, errorCode: ' + event.errorCode.name,
//       }))

//       console.log('Call finished, errorCode: ' + event.errorCode.name)
//       setValuesAfterCall()
//     })
//     call.on(CallsApiEvent.ERROR, (event) => {
//       console.log(
//         'Oops, something went very wrong! Message: ' + JSON.stringify(event)
//       )
//     })
//   }

//   const listenForApplicationCallEvents = (call: WebrtcCall) => {
//     call.on(CallsApiEvent.RINGING, () => {
//       setState((state) => ({
//         ...state,
//         status: 'Ringing...',
//       }))
//       console.log('Call is ringing...')
//     })
//     call.on(CallsApiEvent.ESTABLISHED, (event) => {
//       setState((state) => ({
//         ...state,
//         status: 'Established...',
//       }))
//       setMediaStream(audioRef.current, event.stream)
//     })
//     call.on(CallsApiEvent.HANGUP, (event) => {
//       setState((state) => ({
//         ...state,
//         status: 'Call finished, errorCode: ' + event.errorCode.name,
//       }))
//       console.log('Call finished, errorCode: ' + event.errorCode.name)
//       setValuesAfterCall()
//     })
//     call.on(CallsApiEvent.ERROR, (event) => {
//       console.log(
//         'Oops, something went very wrong! Message: ' + JSON.stringify(event)
//       )
//     })
//   }

//   const audioCall = () => {
//     const applicationCallOptions = ApplicationCallOptions.builder()
//       .setVideo(false)
//       .setCustomData({ scenario: 'dialog' })
//       .build()
//     const activeCall = state.infobipRTC?.callWebrtc(
//       '27d12e7c-64e2-49dc-8b5a-62830cd8efbf'
//     )

//     setState((state) => ({ ...state, activeCall: activeCall }))
//     listenForApplicationCallEvents(activeCall as WebrtcCall)
//   }

//   const setMediaStream = (element: any, stream: any) => {
//     console.log({ element, stream })
//     element.srcObject = stream
//   }

//   const accept = () => {
//     state.activeCall.accept(
//       ApplicationCallOptions.builder().setVideo(true).build()
//     )
//   }

//   const decline = () => {
//     state.activeCall.decline()
//   }

//   const hangup = () => {
//     state.activeCall.hangup()
//   }
//   const shouldShowCallActions = () => {
//     return state.isCallEstablished
//   }
//   const shouldShowButtonsOnIncomingCall = () => {
//     return !state.isCallEstablished && state.isIncomingCall
//   }

//   const setValuesAfterCall = () => {
//     setState((state) => ({
//       ...state,
//       activeCall: null,
//       status: '',
//       selectedAudioQualityMode: 'Auto',
//     }))
//   }

//   const setVideo = (element: any, stream: any) => {
//     if (!element) return
//     if (stream && element.srcObject !== stream) {
//       element.srcObject = stream
//     }
//   }

//   useEffect(() => {
//     if (user) {
//       connectInfobipRTC()
//     }
//   }, [user])
//   return (
//     <Box>
//       <div>
//         <h4>
//           Logged-in as: <span>{state.identity}</span>
//         </h4>

//         <audio ref={audioRef} autoPlay />

//         <div hidden={!shouldShowButtonsOnIncomingCall()}>
//           <button onClick={() => accept()}>Accept</button>
//           <button onClick={() => decline()}>Decline</button>
//           <br />
//           <br />
//         </div>

//         <div hidden={!shouldShowCallActions()}>
//           <button hidden={!shouldShowCallActions()} onClick={() => hangup()}>
//             Hangup
//           </button>
//           <br />
//           <br />
//         </div>
//         <h4>
//           <span>{state.status}</span>
//         </h4>
//         <br />
//         <br />
//       </div>
//     </Box>
//   )
// }

export default function ReceiveCall() {
  const { callState } = useContext(CallContext)
  const { data } = useFetch(
    'get one user',
    `/public/user/users/${callState.callerId}`
  )
  console.log({ data })

  const accept = () => {
    callState.activeCall.accept(
      ApplicationCallOptions.builder().setVideo(false).build()
    )
  }

  const decline = () => {
    callState.activeCall.decline()
  }
  if (data) {
    return (
      <Box
        sx={{
          position: 'absolute',
          width: 400,
          height: 360,
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          boxShadow: '4px 4px 10px 1px rgba(0,0,0,0.07)',
          borderRadius: 5,
          bgcolor: '#33D1C8',
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2, pt: 3 }}>
          <Avatar sx={{ width: 85, height: 85 }} src={data?.user?.imageUrl} />
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            // gap: 1,
            alignItems: 'center',
          }}
        >
          <Typography sx={{ color: 'white', fontSize: 28 }}>
            {data?.user?.firstName + ' ' + data?.user?.lastName}
          </Typography>
          <Typography sx={{ color: 'white', fontSize: 18 }}>
            {data?.user?.email}
          </Typography>
          <Box
            sx={{
              bgcolor: 'gray',
              borderRadius: 5,
              py: 1,
              px: 3,
              mt: 1,
            }}
          >
            <Typography
              sx={{
                fontSize: 18,
              }}
            >
              {data?.user?.role?.name?.replace('_', ' ')}
            </Typography>
          </Box>
        </Box>
        <Divider sx={{ mx: 5, my: 2, color: 'white', bgcolor: 'white' }} />
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: 5,
          }}
        >
          <IconButton
            sx={{ bgcolor: 'green', '&:hover': { bgcolor: 'lightgreen' } }}
            onClick={() => accept()}
          >
            <CallOutlined sx={{ color: 'white', width: 40, height: 40 }} />
          </IconButton>
          <IconButton
            sx={{ bgcolor: '#C44141', '&:hover': { bgcolor: 'red' } }}
            onClick={() => decline()}
          >
            <CallEndOutlined sx={{ color: 'white', width: 40, height: 40 }} />
          </IconButton>
        </Box>
      </Box>
    )
  } else {
    return <></>
  }
}
